<template>
  <v-dialog
    :max-width="mdUp ? '30%' : '90%'"
    v-model="bEditSupplier"
    persistent
  >
    <v-card class="rounded-xl" style="padding-bottom: 15px; padding-top: 15px">
      <v-icon
        @click="close()"
        color="#000000"
        style="position: absolute; padding: 5px; right: 20px"
      >
        mdi-close
      </v-icon>
      <v-card-title class="headline justify-center" style="text-align: center">
        Editar proveedor
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="itemToEdit.sFullName"
                clearable
                class="AddProviderTextField"
                placeholer="Nombre del proveedor"
                label="Nombre del proveedor"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="itemToEdit.sCompanyName"
                clearable
                dense
                class="AddProviderTextField"
                placeholer="Nombre de la empresa"
                label="Nombre de la empresa"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="itemToEdit.sRFC"
                clearable
                dense
                class="AddProviderTextField"
                placeholer="RFC"
                label="RFC"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="itemToEdit.sEmail"
                clearable
                dense
                class="AddProviderTextField"
                placeholer="Correo electrónico"
                label="Correo electrónico"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="itemToEdit.sCompanyEconomicActivity"
                clearable
                dense
                class="AddProviderTextField"
                placeholer="Rubro"
                label="Rubro"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-layout justify-center>
        <v-card-actions justify-center>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" pa-3>
                <v-btn
                  @click="close()"
                  x-large
                  color="black"
                  width="175"
                  outlined
                  style="
                    height: 40px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                  "
                >
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6" pa-3>
                <v-btn
                  :loading="bLoading"
                  @click="confirmEdit"
                  x-large
                  width="175"
                  color="primary"
                  style="
                    height: 40px;
                    color: black;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 5px;
                    font-size: 13px;
                    font-weight: 600;
                  "
                >
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    itemToEdit: {
      sFullName: "",
      sEmail: "",
      sCompanyName: "",
      sRFC: "",
      sCompanyEconomicActivity: "",
    },
    bLoading: false,
  }),
  props: {
    mdUp: Boolean,
  },
  methods: {
    confirmEdit() {
      this.bLoading = true;
      var body = encode.encodeJSON(this.itemToEdit);
      db.put(
        `${uri}/api/v1/supplier/` + this.objSupplierToEdit.sSupplierId,
        body,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          this.bLoading = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
          this.close();
          this.itemToEdit = {
            sFullName: "",
            sEmail: "",
            sCompanyName: "",
            sRFC: "",
            sCompanyEconomicActivity: "",
          };
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    close() {
      this.$store.commit("closeEditSupplier");
    },
  },
  watch: {
    objSupplierToEdit: function () {
      // replicating object in local instance in order to avoid user interaction editing real object in table
      this.itemToEdit.sFullName = this.objSupplierToEdit.sFullName;
      this.itemToEdit.sEmail = this.objSupplierToEdit.sEmail;
      this.itemToEdit.sCompanyName = this.objSupplierToEdit.sCompanyName;
      this.itemToEdit.sRFC = this.objSupplierToEdit.sRFC;
      this.itemToEdit.sCompanyEconomicActivity = this.objSupplierToEdit.sCompanyEconomicActivity;
    },
  },
  computed: {
    // picker edit dialog state
    bEditSupplier() {
      return this.$store.state.bEditSupplier;
    },
    objSupplierToEdit() {
      return this.$store.state.objSupplierToEdit;
    },
  },
};
</script>